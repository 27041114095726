.footer {
    padding-top:  10px;
    padding-bottom: 1px;
    text-align: center;
    border-top: 1px solid #ddd;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-light {
    background-color: #f9f9f9;
    color: #333;
  }
  
  .footer-dark {
    background-color: #303030;
    color: #fff;
  }
  
  .footer-content {
    margin: 0;
    font-size: 14px;
  }
  
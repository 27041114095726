.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  top: 0;
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
}

.logoImg {
  height: 40px;
  width: 120px;
  margin-top: 5px;
}

.nav-menu {
  display: flex;
  align-items: center;
  flex-grow: 1; /* Added this line */
}

.rightIcons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.darkModeButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
}

.loginButton,
.logoutButton {
  background: linear-gradient(to top right, #e3342f, #ef5753);
  border: 1px solid #cc1f1a;
  border-radius: 0.375rem;
  padding: 0.625rem 1rem;
  margin: 0.25rem;
  cursor: pointer;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, border-color 0.3s;
}

.loginButton:hover,
.logoutButton:hover {
  background: linear-gradient(to top right, #e3342f, #e3342f);
}

.nav-ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0 auto; /* Center-align the nav-ul */
}

.nav-ul li {
  display: inline;
}

.nav-ul li a {
  text-decoration: none;
  color: inherit;
  padding: 10px;
  border-radius: 5px;
}

.nav-ul li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 24px;
  margin-left: auto;
}

@media (max-width: 768px) {
  
  .nav-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #191919;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }

  .nav-menu.open {
    display: flex;
    gap: 20px;
    flex-direction: column;
    /* align-items: center; */
    
    
  }

  .nav-ul {
    /* flex-direction: row; */
    gap: 10px;
    align-items: center;
  
  }

  .rightIcons {
    flex-direction: row-reverse;
    gap: 10px;
  }

  .hamburger {
    display: block;
  }

  .nav-right {
    display: flex;
    align-items: center;
  }

  .darkModeButton {
    order: 1;
  }

  .hamburger {
    order: 2;
  }
}

.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 1.5rem;
  user-select: none;
}

.text-div{
  width: 95%;
}
@media only screen and (max-width: 600px) {
  .topic-description {
    font-size: 14px;
  }
  .text-div{
    width: 95%;
  }
}

.checkbox-wrapper:hover .check {
  stroke-dashoffset: 0;
}

.checkbox-wrapper {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 40px;
}

.checkbox-wrapper .background {
  fill: #fff;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper .stroke {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  stroke-dashoffset: 100;
  stroke-dasharray: 100;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper .check {
  fill: none;
  stroke: #ffff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
  stroke-dashoffset: 22;
  stroke-dasharray: 22;
  transition: ease all 0.6s;
  -webkit-transition: ease all 0.6s;
}

.checkbox-wrapper input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  appearance: none;
  -webkit-appearance: none;
}

.checkbox-wrapper input[type="checkbox"]:hover {
  cursor: pointer;
}

.checkbox-wrapper input[type="checkbox"]:checked + svg .background {
  fill: #ee4b2b;
}

.checkbox-wrapper input[type="checkbox"]:checked + svg .stroke {
  stroke-dashoffset: 0;
}

.checkbox-wrapper input[type="checkbox"]:checked + svg .check {
  stroke-dashoffset: 0;
}





.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: relative;
  display: inline-block;
  height: 0.8em;
  width: 0.8em;
  background-color: #ccc;
  border-radius: 50%;
  transition: background-color 300ms ease-in-out;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color: #ee4b2b;
  border-radius: 0.5rem;
  animation: pulse 500ms ease-in-out;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  /* tickmark yaha hai */
  display: block;
  left: 0.28em;
  top: 0.12em;
  width: 0.25em;
  height: 0.5em;
  border: solid #e0e0e2;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 #ee4b2b;
    rotate: 20deg;
  }
  50% {
    rotate: -20deg;
  }
  75% {
    box-shadow: 0 0 0 10px #ee4b2b;
  }
  100% {
    box-shadow: 0 0 0 13px #0b6e4f30;
    rotate: 0;
  }
}
